.user {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  transition: background-color 100ms ease;

  &:hover {
    background-color: light-dark(
      var(--mantine-color-white),
      var(--mantine-color-dark-8)
    );
  }
}
