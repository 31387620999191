.root {
  position: absolute;
  height: var(--header-height);
  background: transparent;
  width: 100%;
  z-index: 1;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  padding-top: var(--mantine-spacing-md);
  padding-bottom: var(--mantine-spacing-sm);
  border-bottom: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

  @media (min-width: $mantine-breakpoint-md) {
    position: relative;
  }
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 500;
  font-size: var(--mantine-font-size-md);

  @media (max-width: $mantine-breakpoint-sm) {
    height: rem(42px);
    width: 100%;
  }
}

.link:hover {
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-6)
  );
}

.subLink {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
}

.subLink:hover {
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-7)
  );
}

.dropdownFooter {
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-7)
  );
  margin: calc(var(--mantine-spacing-md) * -1);
  margin-top: var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: rem(1px) solid
    light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}
