.root {
  cursor: pointer;
}

.icon {
  opacity: 0.7;
  position: absolute;
  left: 3px;
  top: 3px;
}
