.root {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: calc(100% - 25px);
  max-width: 26em;
  z-index: 100;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.toggle {
  position: absolute;
  right: -25px;
  width: 25px;
  top: calc(30%);
  height: 100px;
  background: white;
  border-radius: 0 4px 4px 0;
  overflow: hidden;
  box-shadow: 0 0 0 2px #0000001a;
  z-index: -1;
}

.toggle button {
  position: absolute;
  background: none;
  border: none;
  height: 100%;
  weidth: 100%;
  cursor: pointer;
  color: var(--mantine-color-gray-8);
}

.inner {
  overflow: auto;
  height: 100%;
  padding: var(--mantine-spacing-xl);
}

.folded {
  width: 7px;
}

.folded .inner {
  display: none;
}
