.root {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 2px #0000001a;
  opacity: 0.85;
}

.root:hover {
  opacity: 1;
}

.root a {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mantine-color-white);
}
