.root {
  height: 100%;
  position: relative;
}

.controls,
.legend {
  position: absolute;
  left: var(--mantine-spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-xs);
  align-items: flex-start;
}

.controls {
  top: var(--mantine-spacing-md);
  pointer-events: none;
}

.controls > * {
  pointer-events: auto;
}

.legend {
  bottom: var(--mantine-spacing-md);
}
