.root {
  background: white;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: white;
  background: var(--primary-color-light);
}
