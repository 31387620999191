.root {
  display: flex;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 2px #0000001a;
}

.root a {
  color: inherit;
}
